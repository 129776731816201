import React from 'react'
import './accordion.css'

export default class Accordion extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      hovers: props.materials.map(() => false)
    }
  }

  onHoverElement (i) {
    const hovers = this.state.hovers
    hovers[i] = true
    this.setState({ hovers })
  }

  onExitElemnt (i) {
    const hovers = this.state.hovers
    hovers[i] = false
    this.setState({ hovers })
  }

  onCheck (i) {
    const hovers = this.state.hovers
    hovers[i] = !hovers[i]
    this.setState({ hovers })
  }

  render () {
    const { materials, langKey } = this.props
    return (
      <div className='shadow-md'>
        {
          materials.map((el, i) => (
            <div key={`acc_${i}`} className='tab w-full overflow-hidden border-t' onMouseEnter={this.onHoverElement.bind(this, i)} onMouseLeave={this.onExitElemnt.bind(this, i)}>
              {
                el.subcategory.length > 0 && (
                  <input key='acc_input' checked={this.state.hovers[i]} onChange={this.onCheck.bind(this, i)} className='absolute opacity-0' id={`tab-single-${i}`} type='radio' name='tabs2' />
                )
              }
              <label key='acc_lable' className='block p-5 leading-normal cursor-pointer truncate relative pr-16' htmlFor={`tab-single-${i}`}>{el.category[langKey]}</label>
              <div className='tab-content overflow-hidden border-l-2 bg-gray-100 border-green-gefa leading-normal'>
                {
                  el.subcategory.map((subCat, j) => <p key={`sub_cat_${j}`} className='p-5'>{subCat[langKey]}</p>)
                }
              </div>
            </div>
          ))
        }
      </div>
    )
  }
}
